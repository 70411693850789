import React, { useEffect, useState } from "react"
import { navigate } from "gatsby-link"
import { Button, TextField } from "@mui/material"
import SendIcon from "@mui/icons-material/Send"
import gsap, { Elastic } from "gsap/all"
import { useContext } from "react"
import { BreadCrumbs, Context } from "../../components"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const index = () => {
  const [inputValue, setInputValue] = useState()
  const { state: { isMenuOpen } = { isMenuOpen: false } } =
    useContext(Context) || {}

  useEffect(() => {
    if (!isMenuOpen) animateContact()
  }, [isMenuOpen])

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  const animateContact = () => {
    gsap
      .timeline({
        defaults: {
          ease: Elastic.easeOut.config(0.4, 0.5),
        },
      })
      .set(".form-fields > *", {
        stagger: 0,
        autoAlpha: 0.3,
        y: 100,
      })
      .to(".form-fields > *", {
        stagger: 0.1,
        autoAlpha: 1,
        y: 0,
      })
  }

  const handleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...inputValue,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error))
  }

  return (
    <section className="contact">
      <div className="container">
        <BreadCrumbs crumbs={{ current: "Contact" }} />
        <h1 className="title">Contact</h1>
        <div className="contact__content">
          <form
            name="contact"
            method="post"
            action="/contact/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <label>
                Don’t fill this out:
                <input name="bot-field" onChange={handleChange} />
              </label>
            </div>
            <div className="form-fields">
              <TextField
                id="standard-basic"
                variant="outlined"
                type="text"
                label="Name"
                name="name"
                htmlFor={"name"}
                onChange={handleChange}
                required={true}
              />
              <TextField
                id="standard-basic"
                variant="outlined"
                type="email"
                label="Email"
                name="email"
                htmlFor={"email"}
                onChange={handleChange}
                required={true}
              />
              <TextField
                multiline
                style={{ color: "white" }}
                id="standard-multiline-static"
                variant="outlined"
                htmlFor={"message"}
                name="message"
                label="Message"
                rows={5}
                onChange={handleChange}
                required={true}
                variant="outlined"
              />
              <Button
                type="submit"
                color="secondary"
                className="button"
                size={"small"}
                disableElevation
                variant="contained"
              >
                Send &nbsp; <SendIcon />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default index
